/* src/app/@theme/layouts/toolbar/toolbar-right/toolbar-right.component.scss */
::ng-deep .dropdown-notification {
  min-width: 450px !important;
  max-width: 100% !important;
}
::ng-deep .dropdown-notification .topic-icon .pc-icon {
  width: 22px;
  height: 22px;
}
::ng-deep .dropdown-notification .topic-description .mdc-button {
  border-radius: 20px;
}
::ng-deep .dropdown-notification .notification-card:hover {
  background: var(--accent-200);
  cursor: pointer;
}
::ng-deep .dropdown-user-profile {
  min-width: 352px !important;
  max-width: 100% !important;
}
::ng-deep .dropdown-user-profile .user-title {
  font-size: 16px !important;
  font-weight: 600 !important;
  margin: 0px !important;
}
::ng-deep .dropdown-user-profile .user-name {
  font-weight: 600 !important;
  margin: 0px !important;
}
::ng-deep .dropdown-user-profile .dropdown-item {
  display: flex;
  padding: 10px 15px;
  align-items: center;
  border-radius: 8px;
}
::ng-deep .dropdown-user-profile .dropdown-item:hover,
::ng-deep .dropdown-user-profile .dropdown-item:focus {
  color: var(--accent-900);
  background: var(--accent-300);
  border-color: var(--accent-300);
}
::ng-deep .dropdown-user-profile .dropdown-item .pc-icon {
  width: 18px;
  height: 18px;
}
::ng-deep .dropdown-user-profile .user-group .avatar {
  width: 30px;
  height: 30px;
  font-size: 14px;
}
::ng-deep .dropdown-user-profile .user-logout.mdc-button {
  border-radius: 20px;
}
::ng-deep .dropdown-user-profile .user-followers {
  margin: 16px 0px;
  color: #8996a4;
}
::ng-deep .dropdown-user-profile .upgrade-button {
  background: #e58a00 !important;
  color: #f3f5f7 !important;
}
::ng-deep .dropdown-user-profile .upgrade-button.mdc-button {
  border-radius: 20px;
}
::ng-deep .user-account-card {
  background-image: url("./media/img-profile-card.jpg");
}
.dropdown-header,
.dropdown-body {
  padding: 16px 20px;
}
@media only screen and (max-width: 1024px) {
  .pc-header {
    left: 0px;
    top: 0px;
  }
}
@media (max-width: 575.98px) {
  .pc-header .header-wrapper {
    padding: 10px;
  }
  .pc-header .pc-head-link {
    margin: 0px;
  }
  .pc-header .header-item.mdc-button {
    min-width: 50px;
    padding: 0;
  }
  .pc-header .mdc-button {
    min-width: 0px !important;
  }
  .pc-header .mat-drawer-content .app-container {
    padding: 15px;
  }
  ::ng-deep .dropdown-notification {
    min-width: 350px !important;
  }
}
/*# sourceMappingURL=toolbar-right.component.css.map */

/* src/app/@theme/layouts/toolbar/toolbar-left/toolbar-left.component.scss */
::ng-deep .dropdown-item-search {
  min-width: 20rem !important;
  border-radius: 8px;
}
::ng-deep .dropdown-item-search .mat-mdc-form-field-subscript-wrapper {
  display: none !important;
}
::ng-deep .dropdown-item-search .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: var(--accent-50) !important;
}
::ng-deep .dropdown-item-search .mdc-text-field {
  position: inherit;
  will-change: inherit;
}
::ng-deep .dropdown-item-search .drp-search {
  padding: 8px 16px;
}
/*# sourceMappingURL=toolbar-left.component.css.map */

@for (breadcrumb of navigationList; track breadcrumb; let last = $last) {
  @if (last && breadcrumb.breadcrumbs !== false) {
    <div class="page-header">
      <div class="page-block">
        <div class="row align-item-center">
          <div class="col-md-12">
            <div class="flex align-item-center">
              <ul class="breadcrumb align-item-center">
                <li class="breadcrumb-item">
                  <a [routerLink]="['/dashboard/default']">Home</a>
                </li>
                @for (breadcrumb of navigationList; track breadcrumb) {
                  @if (breadcrumb.url !== false) {
                    <li class="breadcrumb-item">
                      <a [routerLink]="breadcrumb.url">{{ breadcrumb.title }}</a>
                    </li>
                  }
                  @if (breadcrumb.url === false) {
                    <li class="breadcrumb-item">
                      <a href="javascript:">{{ breadcrumb.title }}</a>
                    </li>
                  }
                }
              </ul>
            </div>
          </div>
          <div class="col-md-12">
            <div class="page-header-title">
              @for (breadcrumb of navigationList; track breadcrumb; let last = $last) {
                @if (last) {
                  <h2 class="f-w-700">
                    {{ breadcrumb.title }}
                  </h2>
                }
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

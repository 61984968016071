// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import packageInfo from '../../package.json';

const urlLocal = 'http://localhost:9002/';
const urlLocalIP_0 = 'http://192.168.100.172:9002/';
const urlLocalIP = 'http://192.168.43.213:9002/';
const connectToProduction = 'https://api.dashboard.syl20n.com/';
const connectToDevelop = 'https://api.dashboard.dev.syl20n.com/';
export const environment = {
  appVersion: packageInfo.version,
  production: false,
  apiUrl: connectToDevelop,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

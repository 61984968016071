/* src/app/@theme/layouts/toolbar/toolbar.component.scss */
::ng-deep .pc-header {
  min-height: 0px;
  box-shadow: none;
  position: fixed;
  z-index: 1025;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
::ng-deep .pc-header .header-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  flex-grow: 1;
}
::ng-deep .pc-header .header-button {
  display: inline-flex;
}
::ng-deep .pc-header .header-item {
  min-height: 54px;
  display: flex;
  align-items: center;
  position: relative;
}
::ng-deep .pc-header .header-item.mdc-button {
  min-width: 54px;
}
::ng-deep .pc-header .pc-head-link {
  margin: 0 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
}
::ng-deep .pc-header .pc-head-link > svg,
::ng-deep .pc-header .pc-head-link > i {
  position: relative;
  color: var(--accent-600);
}
::ng-deep .pc-header .pc-head-link > i {
  font-size: 24px;
}
::ng-deep .pc-header .pc-head-link > svg {
  width: 24px;
  height: 24px;
}
::ng-deep .pc-header .pc-head-link:focus,
::ng-deep .pc-header .pc-head-link:hover {
  text-decoration: none;
  color: var(--accent-600);
}
::ng-deep .pc-header .pc-head-link:focus > svg,
::ng-deep .pc-header .pc-head-link:focus > i,
::ng-deep .pc-header .pc-head-link:hover > svg,
::ng-deep .pc-header .pc-head-link:hover > i {
  color: var(--accent-600);
  transform: scale(1.08);
}
/*# sourceMappingURL=toolbar.component.css.map */

/* src/app/demo/layout/admin/admin.component.scss */
.app-container {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 84px;
  min-height: calc(100vh - 146px);
}
@media only screen and (max-width: 1024px) {
  ::ng-deep .mat-drawer-content .app-container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
/*# sourceMappingURL=admin.component.css.map */

/* src/app/@theme/layouts/breadcrumb/breadcrumb.component.scss */
.page-header {
  display: flex;
  align-items: center;
  top: 74px;
  left: 280px;
  right: 0;
  z-index: 1023;
  min-height: 55px;
  padding: 13px 0px;
  background: transparent;
  border-radius: 8px;
}
.page-header .page-block {
  width: 100%;
}
.page-header .page-header-title {
  display: inline-block;
}
.page-header .page-header-title h2 {
  margin: 0px;
}
.page-header h5 {
  margin-bottom: 0;
  margin-right: 8px;
  padding-right: 8px;
  font-weight: 500;
}
.page-header .breadcrumb {
  padding: 0;
  display: inline-flex;
  margin-bottom: 0;
  background: transparent;
  font-size: 13px;
}
.page-header .breadcrumb .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.page-header .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  content: "\ea61";
  font-family: "tabler-icons";
  position: absolute;
  font-size: 15px;
  top: 2px;
  padding-right: 0.5rem;
}
.page-header .breadcrumb a {
  color: var(--accent-900);
}
.page-header .breadcrumb .breadcrumb-item a:hover {
  color: var(--primary-500);
}
.page-header .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  position: relative;
  top: 2px;
}
.page-header .breadcrumb .breadcrumb-item:last-child {
  opacity: 0.75;
}
.page-header .breadcrumb svg {
  width: 14px;
  height: 14px;
  vertical-align: baseline;
}
@media (max-width: 1024px) {
  .page-header {
    position: relative;
    left: 0;
    top: 0;
    margin-left: 10px;
    margin-right: 10px;
  }
}
@media (max-width: 575.98px) {
  .page-header {
    padding: 0px;
    margin: 0px;
  }
}
/*# sourceMappingURL=breadcrumb.component.css.map */

<footer class="theme-footer">
  <div class="footer-wrapper">
    <div class="flex align-item-center">
      <div class="col m-t-5 m-b-5">
        <p class="m-0">
          Able Pro &#9829; crafted by Team
          <a href="https://themeforest.net/user/phoenixcoded" target="_blank">Phoenixcoded</a>
        </p>
      </div>
      <div class="col-auto">
        <ul class="list-inline footer-link m-b-0">
          <li class="list-inline-item">
            <a href="https://ableproadmin.com/">Home</a>
          </li>
          <li class="list-inline-item">
            <a href="https://codedthemes.gitbook.io/able-pro-angular/" target="_blank">Documentation</a>
          </li>
          <li class="list-inline-item">
            <a href="https://phoenixcoded.authordesk.app/" target="_blank">Support</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>

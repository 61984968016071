/* src/app/@theme/layouts/footer/footer.component.scss */
.theme-footer {
  position: relative;
  padding: 15px 0;
}
.theme-footer a {
  color: var(--accent-900);
}
.theme-footer a:hover {
  color: var(--primary-500);
}
.theme-footer .footer-wrapper {
  padding-left: 40px;
  padding-right: 40px;
}
.theme-footer .footer-wrapper .col {
  flex: 1 0 0%;
}
.theme-footer .footer-wrapper .col-auto {
  flex: 0 0 auto;
  width: auto;
}
.theme-footer .footer-wrapper .list-inline {
  padding-left: 0;
  list-style: none;
}
.theme-footer .footer-wrapper .list-inline .list-inline-item {
  display: inline-block;
}
.theme-footer .footer-link .list-inline-item:not(:last-child) {
  margin-right: 0.9rem;
}
@media (max-width: 575.98px) {
  .theme-footer {
    padding: 0px 0px 15px 0;
  }
  .theme-footer .footer-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  .theme-footer .footer-wrapper > div {
    flex-direction: column;
  }
}
/*# sourceMappingURL=footer.component.css.map */
